import { CheckboxProps, SelectProps, TextFieldProps } from '@mui/material'
import { DatePickerProps } from '@mui/x-date-pickers'
import React, { FormEvent, useState } from 'react'

import omit from 'utils/omit'

export interface Validator<T, K extends keyof T, V = T[K]> {
  // message is the message that the user will see if the value is invalid
  message: string
  // validators validate a specific attribute and return true if the value is valid
  validator?: ((_: V) => boolean) & { required?: boolean }
  // conditionals get passed all the values in order to validate that a value is valid
  //  depending on another value in the form
  conditional?: (_: T) => boolean
}

export type Validations<T> = Partial<{
  [K in keyof T]: Validator<T, K>[]
}>

export type ValueFormatter<T> = (_: T) => T

export type ValueFormatters<T> = Partial<{
  [K in keyof T]: ValueFormatter<T[K]>
}>

type Errors<T> = Partial<{
  [K in keyof T]: string
}>

const useForm = <T, Keys extends Extract<keyof T, string>, SKeys extends KeysOfValue<T, string>>(
  initialValues: T,
  {
    validations = {},
    valueFormatters = {},
    includeWhitespaceKeys = [], // use this to specify the keys who's values you do no want skipped
    afterChange = () => {},
  }: {
    validations?: Validations<T>
    // value formatters get ran when the value changes allowing you to alter the value
    valueFormatters?: ValueFormatters<T>
    includeWhitespaceKeys?: SKeys[]
    afterChange?: () => void
  } = {},
) => {
  const [values, setValues] = useState(initialValues)
  const [errors, setErrors] = useState<Errors<T>>({})
  type IncludeWhitespace = {
    [key in SKeys]: true
  }
  const includeWhitespace = {} as IncludeWhitespace
  includeWhitespaceKeys.forEach((key) => {
    includeWhitespace[key] = true
  })

  const normalizeValues = (rawValues: T): T => {
    const vals = {} as T
    Object.entries(rawValues).forEach(([key, value]) => {
      let v = value
      if (typeof v === 'string' && !includeWhitespace[key]) {
        v = v.trim()
      }
      vals[key] = v
    })
    return vals
  }

  type TKeysContainingValue<TCondition> = {
    [K in Keys]: T[K] extends TCondition ? K : never
  }[Keys]

  const validationError = <K extends Keys, V extends T[K]>(
    name: K,
    value: V = undefined,
  ): string | null => {
    const failedValidation = (validations[name] || []).find((validation) => {
      const val = value === undefined ? values[name] : value
      if (validation.validator && !validation.validator(val)) return true
      if (validation.conditional) return !validation.conditional(normalizeValues(values))

      return false
    })

    return failedValidation?.message || null
  }

  const validateAll = () => {
    const currentErrors = {}

    Object.keys(validations).forEach((name) => {
      const error = validationError(name as Keys)
      if (error) currentErrors[name] = error
    })

    setErrors(currentErrors)
    return Object.keys(currentErrors).length === 0
  }

  const validate = <K extends Keys, V extends T[K]>(name: K, value: V) => {
    const error = validationError(name, value)
    if (error) {
      setErrors({ ...errors, [name]: error })
      return false
    }

    setErrors(omit(errors, name))
    return true
  }

  const isRequired = <K extends Keys>(name: K) =>
    (validations[name] || []).some(({ validator }) => validator?.required)

  const onChange = <K extends Keys, V extends T[K]>(name: K, value: V) => {
    const formatter = valueFormatters[name]
    const val = formatter ? formatter(value) : value
    setValues({ ...values, [name]: val })
    if (errors[name]) validate(name, val)
    afterChange()
  }

  const onBlur =
    <K extends Keys>(name: K) =>
    () =>
      validate(name, values[name])

  const checkboxProps = <K extends TKeysContainingValue<boolean>>(name: K): CheckboxProps => ({
    id: name,
    checked: values[name] as boolean,
    required: isRequired(name),
    onChange: ({ target: { checked } }) => onChange(name, checked as T[K]),
  })

  const textFieldProps = <K extends TKeysContainingValue<string>>(name: K): TextFieldProps => ({
    id: name,
    value: values[name],
    error: !!errors[name],
    helperText: errors[name],
    required: isRequired(name),
    onChange: ({ target: { value } }) => onChange(name, value as T[K]),
    onBlur: onBlur(name),
  })

  const selectProps = <K extends TKeysContainingValue<SelectValues>>(
    name: K,
  ): SelectProps<T[K]> => ({
    id: name,
    value: values[name],
    error: !!errors[name],
    required: isRequired(name),
    onChange: ({ target: { value } }) => onChange(name, value as T[K]),
    onBlur: onBlur(name),
  })

  const datePickerProps = <K extends TKeysContainingValue<Date>>(
    name: K,
  ): DatePickerProps<Date> => ({
    value: values[name] as Date,
    slotProps: {
      textField: {
        required: isRequired(name),
        error: !!errors[name],
        helperText: errors[name],
        onBlur: onBlur(name),
      },
    },
    onChange: (value) => onChange(name, value as T[K]),
  })

  const handleFormSubmit =
    (onSuccess: (_: T) => void): React.FormEventHandler<HTMLFormElement> =>
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      if (validateAll()) onSuccess(normalizeValues(values))
    }

  const resetForm = () => {
    setValues(initialValues)
    setErrors({})
  }

  return {
    checkboxProps,
    textFieldProps,
    handleFormSubmit,
    selectProps,
    datePickerProps,
    values,
    errors,
    resetForm,
  }
}

export default useForm
