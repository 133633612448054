const emailValidator = (value: string) => /^[^@]+@[^@]+$/i.test(value)

const presenceValidator = (value: unknown) =>
  value !== undefined && value !== null && `${value}`.trim().length !== 0
presenceValidator.required = true

const confirmationValidatorGenerator =
  <T>(k1: keyof T, k2: keyof T): ((values: T) => boolean) =>
  (values: T) =>
    values[k1] === values[k2]

const greaterThanValidatorGenerator = (threshold: number) => (value: string) =>
  parseFloat(value) > threshold

const moneyValidator = (amount: string) => /^\d+(\.\d{2})?$/.test(amount)

export {
  emailValidator,
  presenceValidator,
  confirmationValidatorGenerator,
  greaterThanValidatorGenerator,
  moneyValidator,
}
